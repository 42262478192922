import React from 'react';
import { Spin } from 'antd';
import style from './faq.module.scss';
import { Layouts } from '../components/components';
import { Promo, ProjectList, PictureWithText } from '../modules/modules';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';
import { StrapiBlocksName, StrapiMainPagesNames, ProjectCategories } from '../lib/constants/strapi';
import { replaceStrapiComponentName } from '../lib/utils';
import { PromoBlockType, PictureWithTextType } from '../lib/constants/enums';

const TrainingComplex: React.FC = () => {
  const { IndexLayout } = Layouts;
  const { currentPage, projects } = useStrapiPage(StrapiMainPagesNames.trainingComplex);

  return (
    <IndexLayout>
      {currentPage && currentPage.blocks ? (
        currentPage.blocks.map((block, index) => {
          switch (replaceStrapiComponentName(block?.__component)) {
            case StrapiBlocksName.defaultSplash:
              return <Promo data={block} type={PromoBlockType.default} key={index} />;
            case StrapiBlocksName.projectsList:
              return (
                <ProjectList
                  strapiData={block}
                  data={projects?.filter(project => project.pageInfo && project.pageInfo.category === ProjectCategories.trainingComplex)}
                  theme={currentPage.theme}
                  key={index}
                />
              );
            case StrapiBlocksName.descriptionBlock:
              return (
                <PictureWithText
                  description={block?.description || ''}
                  image={(block?.picture && block.picture.url) || ''}
                  type={PictureWithTextType.descriptionBlock}
                  key={index}
                />
              );
            default:
              return null;
          }
        })
      ) : (
        <div className={style.loader}>
          <Spin />
        </div>
      )}
    </IndexLayout>
  );
};

export default TrainingComplex;
